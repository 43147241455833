<template>
  <div class="container">
    <div class="not-found">
      <h1 class="not-found__title">
        404
      </h1>
      <div class="not-found__text">
        Страница, которую вы ищите, не найдена!
      </div>
      <button
        class="not-found__back"
        @click="goHome"
      >
        На главную
      </button>
    </div>
  </div>
</template>

<script>
import routeLabels from '@/router/labels'

export default {
  name: 'PageNotFound',

  mounted () {
    this.$store.commit('users/setPageNotFound', false)
  },
  methods: {
    goHome () {
      this.$router.push({ name: routeLabels.HOME_ROUTE_NAME })
    }
  }
}
</script>

<style lang="sass" scoped>
.not-found
    padding: 4rem 2rem
    .not-found__title
       font-size: 150px
       color: #008B62
.not-found__text
  margin: 2rem 0
  font-size: 20px
  font-weight: 600
  color: #444
.not-found__back
  display: inline-block
  border: 2px solid #222
  color: #222
  text-transform: uppercase
  font-weight: 600
  padding: 0.75rem 1rem 0.6rem
  transition: all 0.2s linear
  box-shadow: 0 3px 8px rgba(0,0,0, 0.3)
  cursor: pointer
  &:hover
    background: #222
    color: #ddd
</style>
